var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "call-type-icone" },
    [
      _c("svg-icon", {
        attrs: {
          name: ["in", "aut", "un", "un"][_vm.data.type] + "-call",
          width: "16",
          height: "16",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }